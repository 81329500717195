*,
*:before,
*:after {
	box-sizing: border-box; }

html {
	scroll-behavior: smooth; }

body {
	font-family: 'Raleway', sans-serif;
	margin: 0;
	overflow: hidden;
	overflow-y: auto;
	transition: all .3s;
	&::-webkit-scrollbar {
		width: 12px; }
	&::-webkit-scrollbar-thumb {
		background: rgba(0,0,0,.2);
		border-radius: 20px; }
	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0,0,0,.75);
		box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2); }
	&::-webkit-scrollbar-thumb:active {
		background-color: rgba(0,0,0,.75); }
	&::-webkit-scrollbar-track {
		background: rgba(black,.05);
		border-radius: 20px; } }

h1,h2,h3,h4,h5,h6,p {
	margin: 0; }

p {
	font-weight: 300; }

a, button, input, textarea, select {
	outline: none; }
