@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$raleway: 'Raleway', sans-serif;

// Colors Navidad Pyme
$black: #000000;
$grey-one: #4D4D4D;
$grey-two: #D0D7D9;
$grey-three: #D6D6D6;
$white: #FFFFFF;
$green: #49D30F;
$red: #FF005C;

// Flat Colors
$red-dark: #C8132E;
$magenta: #FF1E56;
$cyan: #006BDD;
$yellow: #FAB900;
$gray: #A8A7AD;
$turquoise-light: #99FFFF;
$turquoise-dark: #2ABAAD;

// WHITE
.bck-white {
	background: $white; }
.border-white {
	border-color: $white !important; }
.color-white {
	color: $white !important; }

// Variables Inputs width
.wi1 {
	width: 100%; }
.wi2 {
	width: calc(100% / 2 - 1rem); }
.wi3 {
	width: calc(100% / 3 - 1rem); }
.wi4 {
	width: calc(100% / 4 - 1rem); }

// Width all
.w5 {
	width: 5%; }
.w7-5 {
	width: 7.5%; }
.w10 {
	width: 10%; }
.w15 {
	width: 15%; }
.w20 {
	width: 20%; }
.w25 {
	width: 25%; }
.w30 {
	width: 30%; }
.w35 {
	width: 35%; }
.w40 {
	width: 40%; }
.w45 {
	width: 45%; }
.w50 {
	width: 50%; }
.w55 {
	width: 55%; }
.w60 {
	width: 60%; }
.w65 {
	width: 65%; }
.w70 {
	width: 70%; }
.w75 {
	width: 75%; }
.w80 {
	width: 80%; }
.w85 {
	width: 85%; }
.w90 {
	width: 90%; }
.w95 {
	width: 95%; }
.w100 {
	width: 100% !important; }

