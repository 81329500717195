.contBck {
	min-height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 0;
	img {
		width: 100%; } }

@include tabletV {
	.contBck {
		img {
			width: 150%; } } }

@include cellVG {
	.contBck {
		img {
			width: 250%; } } }

