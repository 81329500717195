@mixin monitor {
	@media screen and (min-width: 1600px) {
		html {
			font-size: 17px; }
		@content; } }

@mixin laptopMac {
	@media screen and (min-width: 1400px) {
		@content; } }

@mixin tabletH {
	@media screen and (max-width: 1024px) {
		html {
			font-size: 15px; }
		@content; } }

@mixin tabletV {
	@media screen and (max-width: 768px) {
		@content; } }

@mixin cellH {
	@media screen and (max-width: 600px) {
		html {
			font-size: 14px; }
		@content; } }

@mixin cellVG {
	@media screen and (max-width: 480px) {
		@content; } }

@mixin cellVP {
	@media screen and (max-width: 380px) {
		@content; } }



