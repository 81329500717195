.btn {
	background: none;
	border-color: blue;
	border-style: solid;
	border-width: 1px;
	border-radius: 20px;
	box-shadow: 0 4px 5px rgba($black,.3);
	display: inline-flex;
	cursor: pointer;
	font-family: $raleway;
	font-size: .95rem;
	font-weight: 800;
	overflow: hidden;
	padding: .7rem 2rem;
	position: relative;
	text-decoration: none;
	span {
		position: relative;
		text-align: center;
		transition: all .4s;
		width: 100%;
		z-index: 3; }
	&::before {
		content: '';
		height: 0;
		left: 0;
		position: absolute;
		top: 0;
		transition: all .4s;
		width: 100%;
		z-index: 1; }
	&:hover {
		&::before {
			height: 100%; } } }

// BUTTON STYLES
.btnBck {
	background: gray;
	transition: all .4s; }

.btnBckGreen {
	border-color: $green;
	background: $green;
	color: $white;
	&::before {
		background: $red; }
	&:hover {
		border-color: $red;
		&::before {
			background: $red; } } }

.btnBckLineGreen {
	border-color: $green;
	background: $green;
	color: $white;
	&::before {
		background: $red; }
	&:hover {
		border-color: $red;
		span {
			color: $white; }
		&::before {
			background: $red; } } }

.btnBckLine {
	border-color: $grey-two;
	background: $white;
	color: $black;
	&::before {
		background: $red; }
	&:hover {
		border-color: $red;
		span {
			color: $white; }
		&::before {
			background: $red; } } }



.btnBckNone {
	border-color: $white;
	background: $white;
	box-shadow: none;
	color: $black;
	&::before {
		background: $white; }
	&:hover {
		border-color: $white;
		span {
			color: $red; }
		&::before {
			background: $white; } } }

