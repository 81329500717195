.contAll {
	min-height: 100vh;
	position: relative;
	width: 100%;
	z-index: 2;
	&.centrado {
		align-items: center;
		display: flex;
		justify-content: center; }
	&.internal {
		flex-direction: column; }
	&.gracias {
		flex-direction: column;
		padding: 3.5rem 0 0 0; }

 } // Structure box general
.boxCentral {
	border: solid 1px rgba($black,.1);
	background: $white;
	border-radius: 7px;
	box-shadow: 0 5px 5px rgba($black,.15);
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	overflow: hidden;
	padding: 1rem;
	position: relative;
	width: 65%; }
.contIcons {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 10%;
	img {
		margin: .65rem 0; } }
.contCentro {
	width: 75%; }
.santa {
	top: 25%;
	left: -.25rem;
	position: absolute; }

h3 {
	text-align: center; }

// Logo Pyme
.logoPyme {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 1rem;
	width: 100%;
	h3 {
		margin-bottom: .75rem;
		text-align: center; }
	&.logoHeader {
		margin: 3rem 0 1.5rem 0;
		img {
			width: 15%; } }
	&.tenkiu {
		margin: .5rem 0 7rem 0;
		width: 100%;
		img,
		h3 {
			width: 40%;
			&:last-child {
				text-align: right; } } }
	&.interno {
		display: none; } }

.contentLink {
	margin-top: 5rem; }
// Content important and central, here include all your content, form, text, etc.
.content {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center; }

.bt1 {
	bottom: 1rem; }
// Footer with logos
.contFooter {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 2rem auto 0 auto;
	&.interno {
		margin-bottom: 3rem; } }
.rowFooter {
	align-items: center;
	display: flex;
	justify-content: space-around;
	width: 100%;
	flex-wrap: wrap;
	&.primero {
		width: 65%; }
	&.segundo {
		width: 55%; } }

.contStep {
	margin: 2rem 0;
	text-align: justify;
	width: 100%; }

@include tabletH {
	.santa {
		img {
			width: 17.5rem; } } }

@include tabletV {
	.boxCentral {
		width: 100%;
		height: 100vh;
		&.form {
			height: 100% !important; } }
	.contFooter {
		margin: 0.1rem auto 0 auto; }
	.rowFooter {
		.img1 {
			width: 50%; }
		.img2 {
			width: 50%; }
		.img3 {
			width: 50%; }
		.img4 {
			width: 50%; }
		.img5 {
			width: 50%; }
		.img6 {
			width: 50%; } }
	.logoPyme {
		.w50 {
			width: 65%; }
		&.interno {
			display: inline-flex; } }
	.contAll {
		&.gracias {
			padding: 0 0 0 0;
			.boxCentral {
				height: 50vh; } }
		.logoPyme {
			&.logoHeader {
				margin: 0 0 0 0;
				img {
					display: none; } } } }
	.makeStyles-paper-3 {
		margin: 0 0 0 0 !important; }
	.contentLink {
		margin-top: 1rem; } }


@include cellH {
	.contAll {
		&.centrado {
			justify-content: flex-start;
 } }			//padding: 2.5rem 0
	.santa {
		img {
			width: 16rem; } }
	.logoPyme {
		&.logoHeader {
			margin: 0 0 1.5rem 0;
			img {
				width: 25%; } } }
	.contIcons {
		display: none; }
	.contCentro {
		width: 100%; } }

@include cellVG {
	.contAll {
		&.centrado {
			justify-content: baseline; } }
	.boxCentral {
		padding: 2.5rem 2rem; }
	.santa {
		img {
			width: 14rem; } } }

@include cellVP {
	.boxCentral {
		width: 100%;
		height: 100vh; }
	.contIcons {
		display: none; }
	.contCentro {
		width: 100%; }
	.santa {
		img {
			width: 13rem; } } }

.MuiStepConnector-alternativeLabel {
  left: calc(-68% + 20px) !important;
  right: calc(69% + 20px) !important; }
